import React, { Component } from 'react';
import Slider from 'react-slick';
import '../slick.css';

export default class Carousel extends Component {
	render() {
		console.log(this.props.cards);
		const settings = {
			className: 'center',
			centerMode: true,
			arrows: false,
			infinite: true,
			centerPadding: '50px',
			slidesToShow: 3,
			accessibility: true,
			speed: 500,
			dots: true,
			autoplay: true,
			pauseOnFocus: true,
			pauseOnDotsHover: true,
			responsive: [
				{
					breakpoint: 800,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						centerPadding: '10px',
						infinite: true,
						dots: true,
					},
				},
			],
		};
		return (
			<div className='carousel'>
				<Slider {...settings}>
					{this.props?.cards.map((card, i) => (
						<div className='card' key={i} index={i}>
							<h3>{card.title}</h3>
							<div
								dangerouslySetInnerHTML={{
									__html: card.contentNode.childMarkdownRemark.html,
								}}
							/>
						</div>
					))}
				</Slider>
			</div>
		);
	}
}
