import * as React from 'react'
import { graphql } from 'gatsby'
import '../global.scss'
import { Helmet } from 'react-helmet'
import Favicon from '../images/logo.png'
import Carousel from '../components/carousel'
import {
	Link,
	Trans,
	useTranslation,
	useI18next,
} from 'gatsby-plugin-react-i18next'

// markup
const IndexPage = (props) => {
	const data = props.data?.datoCmsQna
	const { languages, changeLanguage } = useI18next()
	const { t } = useTranslation()

	return (
		<>
			<Helmet>
				<meta name='description' content={data?.seo?.description} />
				<meta name='og:description' content={data?.seo?.description} />
				<meta name='og:image' content={data?.seo?.image?.url} />
				<meta name='viewport' content='width=device-width, initial-scale=1.0' />
				<meta name='twitter:card' content={data?.seo?.twitterCard} />
				<meta
					name='icon'
					href={data?.favicon?.url ? data?.favicon?.url : Favicon}
				/>
				<link
					rel='shortcut icon'
					type='image/jpg'
					href={data?.favicon?.url ? data?.favicon?.url : Favicon}
				/>
				<title>QnA</title>
			</Helmet>
			<header>
				<div class='right'>
					<span className='qa'>
						QUESTIONS <br />
						<span className='orange'>&</span> ANSWERS
					</span>
					<img src={Favicon} alt='QnA Logo' />
					<a className='link' href='#footer'>
						{t('contact')}
					</a>
				</div>
				<ul className='languages'>
					{languages.map((lng) => (
						<li key={lng}>
							<a
								href={`/${lng}`}
								onClick={(e) => {
									e.preventDefault()
									changeLanguage(lng)
								}}
								className={data.locale === lng ? 'selected' : ' '}
							>
								{lng === 'en' ? 'EN' : 'FI'}
							</a>
						</li>
					))}
				</ul>
			</header>

			<main>
				<div className='content'>
					<h1 className='title'>{data?.title}</h1>
					<h2 className='title subtitle'>{data?.subtitle}</h2>
				</div>

				{data?.cards && <Carousel cards={data?.cards} />}

				<div
					className='content content-1'
					dangerouslySetInnerHTML={{
						__html: data?.content1Node.childMarkdownRemark.html,
					}}
				></div>
				<div
					className='content content-2'
					dangerouslySetInnerHTML={{
						__html: data?.content2Node.childMarkdownRemark.html,
					}}
				></div>
				<div
					className='content content-3'
					dangerouslySetInnerHTML={{
						__html: data?.content3Node.childMarkdownRemark.html,
					}}
				></div>
			</main>
			<footer
				id='footer'
				dangerouslySetInnerHTML={{
					__html: data?.footerNode?.childMarkdownRemark.html,
				}}
			></footer>
		</>
	)
}

export default IndexPage

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		datoCmsQna(locale: { eq: $language }) {
			locale
			title
			subtitle
			content1Node {
				childMarkdownRemark {
					html
				}
			}
			content2Node {
				childMarkdownRemark {
					html
				}
			}
			content3Node {
				childMarkdownRemark {
					html
				}
			}
			footerNode {
				childMarkdownRemark {
					html
				}
			}
			cards {
				title
				contentNode {
					childMarkdownRemark {
						html
					}
				}
			}
			favicon {
				url
			}
			seo {
				description
				title
				twitterCard
				image {
					url
				}
			}
		}
	}
`
